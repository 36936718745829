import React, { useState } from 'react';
import { db } from '../firebase'; // Asegúrate de importar correctamente db desde firebase.js
import { collection, addDoc } from 'firebase/firestore';
import { ChromePicker } from 'react-color';

const TagModal = ({ closeModal }) => {
  const [tagName, setTagName] = useState('');
  const [tagColor, setTagColor] = useState('#ffffff'); // Color inicial blanco
  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);

    try {
      await addDoc(collection(db, 'tags'), {
        name: tagName,
        color: tagColor
      });
      alert('Etiqueta creada exitosamente');
      setIsSaving(false);
      closeModal();
    } catch (error) {
      console.error('Error al guardar la etiqueta: ', error);
      setIsSaving(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black opacity-50" onClick={closeModal}></div>
      <div className="bg-white p-8 rounded shadow-md z-50 w-full max-w-lg">
        <h2 className="text-2xl mb-4">Crear Etiqueta</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">Nombre de la etiqueta</label>
            <input
              type="text"
              value={tagName}
              onChange={(e) => setTagName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">Color</label>
            <ChromePicker
              color={tagColor}
              onChangeComplete={(color) => setTagColor(color.hex)}
              disableAlpha={true}
              className="w-full"
            />
          </div>
          <div className="flex justify-end">
            <button type="button" onClick={closeModal} className="bg-gray-300 text-black px-4 py-2 rounded mr-2">Cancelar</button>
            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded" disabled={isSaving}>Crear</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TagModal;
