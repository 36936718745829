import React, { useState, useEffect, Fragment } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { db, auth } from '../firebase';
import { collection, onSnapshot, doc, getDoc, updateDoc, getDocs } from 'firebase/firestore'; 
import { Dialog, Transition } from '@headlessui/react';
import { FaWhatsapp } from 'react-icons/fa';

const FunnelPage = () => {
  const [prospects, setProspects] = useState({});
  const [tags, setTags] = useState([]);
  const [selectedProspect, setSelectedProspect] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [visits, setVisits] = useState([]);

  useEffect(() => {
    const fetchTagsAndProspects = async () => {
      const tagsSnapshot = await getDocs(collection(db, 'tags'));
      const tagData = {};
      const tagList = [];
      
      tagsSnapshot.forEach((doc) => {
        tagData[doc.id] = [];
        tagList.push({ id: doc.id, ...doc.data() });
      });

      setTags(tagList);

      const q = collection(db, 'prospects');
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const data = { ...tagData };

        querySnapshot.forEach((doc) => {
          const prospect = { id: doc.id, ...doc.data() };
          if (data[prospect.tag]) {
            data[prospect.tag].push(prospect);
          }
        });

        setProspects(data);
      });

      return unsubscribe;
    };

    const unsubscribeAuth = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setCurrentUser({ uid: user.uid, ...userDoc.data() });
        } else {
          console.error('No user data found!');
        }
      } else {
        setCurrentUser(null);
      }
    });

    fetchTagsAndProspects();

    return () => {
      unsubscribeAuth();
    };
  }, []);

  const onDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    const sourceColumn = Array.from(prospects[source.droppableId]);
    const destColumn = Array.from(prospects[destination.droppableId]);

    const [movedProspect] = sourceColumn.splice(source.index, 1);
    movedProspect.tag = destination.droppableId;
    destColumn.splice(destination.index, 0, movedProspect);

    const updatedProspects = {
      ...prospects,
      [source.droppableId]: sourceColumn,
      [destination.droppableId]: destColumn
    };

    setProspects(updatedProspects);

    const prospectRef = doc(db, 'prospects', movedProspect.id);
    await updateDoc(prospectRef, { tag: movedProspect.tag });
  };

  const openDrawer = async (prospect) => {
    setSelectedProspect(prospect);
    setIsDrawerOpen(true);
    const prospectDoc = await getDoc(doc(db, 'prospects', prospect.id));
    if (prospectDoc.exists()) {
      const prospectData = prospectDoc.data();
      setVisits(prospectData.visitas || []);
    } else {
      setVisits([]);
    }
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const sendMessage = (prospect) => {
    const phone = prospect.phone.replace(/\D/g, ''); // Eliminar todos los caracteres no numéricos
    const whatsappURL = `https://wa.me/52${phone}`;
    window.open(whatsappURL, '_blank');
  };

  const renderColumn = (tag) => (
    <Droppable droppableId={tag.id} key={tag.id}>
      {(provided) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className="bg-gray-100 p-4 rounded-lg shadow-md w-64 min-h-[500px]"
        >
          <h2 className="text-xl font-bold mb-4">{tag.name}</h2>
          {prospects[tag.id] && prospects[tag.id].map((prospect, index) => (
            <Draggable key={prospect.id} draggableId={prospect.id} index={index}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  className="bg-white p-4 mb-4 rounded-lg shadow-sm cursor-pointer"
                  onClick={() => openDrawer(prospect)}
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="font-medium">{prospect.businessName}</p>
                      <p className="text-sm text-gray-500">{prospect.contactPerson}</p>
                    </div>
                    <FaWhatsapp
                      className="text-green-500 cursor-pointer"
                      size={24}
                      onClick={(e) => {
                        e.stopPropagation();
                        sendMessage(prospect);
                      }}
                    />
                  </div>
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );

  return (
    <div>
      <h1 className="text-3xl font-bold mb-4">Embudo</h1>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="flex space-x-4">
          {tags.map((tag) => renderColumn(tag))}
        </div>
      </DragDropContext>

      <Transition.Root show={isDrawerOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 overflow-hidden z-50" onClose={closeDrawer}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">Información del Cliente</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={closeDrawer}
                          >
                            <span className="sr-only">Close panel</span>
                            <svg
                              className="h-6 w-6"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      {selectedProspect && (
                        <div>
                          <p>
                            <strong>Nombre del Negocio:</strong> {selectedProspect.businessName}
                          </p>
                          <p>
                            <strong>Persona de Contacto:</strong> {selectedProspect.contactPerson}
                          </p>
                          <p>
                            <strong>Teléfono:</strong> {selectedProspect.phone}
                          </p>
                          <p>
                            <strong>Email:</strong> {selectedProspect.email}
                          </p>
                          <p>
                            <strong>Etiqueta:</strong> {selectedProspect.tag}
                          </p>
                          <div className="mt-4">
                            <h3 className="text-lg font-medium text-gray-900">Historial de Visitas</h3>
                            {visits.length > 0 ? (
                              <ul className="mt-2 space-y-2">
                                {visits.map((visit, index) => (
                                  <li key={index} className="flex space-x-3">
                                    <div className="flex-shrink-0">
                                      <svg
                                        className="h-5 w-5 text-gray-400"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M12 5v14m7-7H5"
                                        />
                                      </svg>
                                    </div>
                                    <div>
                                      <p className="text-sm text-gray-500">
                                        {new Date(visit.date.seconds * 1000).toLocaleDateString()} - {visit.note}
                                      </p>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <p className="text-sm text-gray-500">Aún no ha sido visitado.</p>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default FunnelPage;
