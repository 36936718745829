import React, { useState } from 'react';
import ProspectList from './ProspectList';
import LeadsList from './LeadsList';
import Tabs from './Tabs';
import TagModal from './TagModal'; // Importar el nuevo TagModal

const ProspectsPage = () => {
  const [isTagModalOpen, setIsTagModalOpen] = useState(false); // Estado para controlar la visibilidad del TagModal

  const tabs = [
    {
      label: 'Prospectos',
      content: <ProspectList />
    },
    {
      label: 'Leads',
      content: <LeadsList />
    }
  ];

  return (
    <div>
      <Tabs tabs={tabs} />
      <div className="mt-4">
        <button
          type="button"
          onClick={() => setIsTagModalOpen(true)}
          className="bg-green-500 text-white px-4 py-2 rounded"
        >
          Crear Etiqueta
        </button>
      </div>
      {isTagModalOpen && <TagModal closeModal={() => setIsTagModalOpen(false)} />}
    </div>
  );
};

export default ProspectsPage;
