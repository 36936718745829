import React, { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import statesAndMunicipalities from './statesAndMunicipalities.json'; // Asegúrate de tener un archivo JSON con los estados y municipios

const ZoneModal = ({ closeModal }) => {
  const [selectedState, setSelectedState] = useState('');
  const [selectedMunicipality, setSelectedMunicipality] = useState('');
  const [municipalities, setMunicipalities] = useState([]);

  useEffect(() => {
    if (selectedState) {
      const stateData = statesAndMunicipalities.find(state => state.name === selectedState);
      setMunicipalities(stateData ? stateData.municipalities : []);
    } else {
      setMunicipalities([]);
    }
  }, [selectedState]);

  const handleSaveZone = async () => {
    if (!selectedState || !selectedMunicipality) {
      alert('Por favor seleccione un estado y un municipio.');
      return;
    }

    try {
      await addDoc(collection(db, 'zonas'), {
        estado: selectedState,
        municipio: selectedMunicipality,
      });
      alert('Zona creada con éxito.');
      closeModal();
    } catch (error) {
      console.error('Error creando zona:', error);
      alert('Hubo un error creando la zona.');
    }
  };

  return (
    <Transition.Root show={true} as={React.Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-50" onClose={closeModal}>
        <div className="absolute inset-0 overflow-hidden">
          <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={React.Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900">Crear Nueva Zona</Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={closeModal}
                        >
                          <span className="sr-only">Cerrar</span>
                          <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex-1 px-4 sm:px-6">
                    <form className="space-y-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">Estado</label>
                        <select
                          value={selectedState}
                          onChange={(e) => setSelectedState(e.target.value)}
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                          <option value="">Seleccione un estado</option>
                          {statesAndMunicipalities.map((state) => (
                            <option key={state.name} value={state.name}>{state.name}</option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">Municipio</label>
                        <select
                          value={selectedMunicipality}
                          onChange={(e) => setSelectedMunicipality(e.target.value)}
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          disabled={!selectedState}
                        >
                          <option value="">Seleccione un municipio</option>
                          {municipalities.map((municipality, index) => (
                            <option key={index} value={municipality}>{municipality}</option>
                          ))}
                        </select>
                      </div>
                    </form>
                    <div className="mt-6 flex justify-end">
                      <button
                        type="button"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={handleSaveZone}
                      >
                        Guardar
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ZoneModal;
