import React, { useState } from 'react';

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].label);

  const handleTabClick = (label) => {
    setActiveTab(label);
  };

  return (
    <div>
      <div className="tabs">
        {tabs.map((tab) => (
          <button
            key={tab.label}
            className={`tab-button ${activeTab === tab.label ? 'active' : ''}`}
            onClick={() => handleTabClick(tab.label)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="tab-content">
        {tabs.map((tab) => (
          <div
            key={tab.label}
            className={`tab-panel ${activeTab === tab.label ? 'active' : ''}`}
          >
            {tab.content}
          </div>
        ))}
      </div>
      <style jsx>{`
        .tabs {
          display: flex;
          border-bottom: 1px solid #ddd;
        }
        .tab-button {
          padding: 10px 20px;
          cursor: pointer;
          border: none;
          background: none;
          font-size: 16px;
          margin-right: 5px;
          border-bottom: 2px solid transparent;
        }
        .tab-button.active {
          border-bottom: 2px solid #0073e6;
          font-weight: bold;
        }
        .tab-content {
          padding: 20px;
          background: #f9f9f9;
        }
        .tab-panel {
          display: none;
        }
        .tab-panel.active {
          display: block;
        }
      `}</style>
    </div>
  );
};

export default Tabs;
