import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { db } from '../firebase';
import { collection, onSnapshot, getDocs, query, where } from 'firebase/firestore';

const DashboardPage = () => {
  const [prospectData, setProspectData] = useState([]);
  const [tagMap, setTagMap] = useState({});
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [salesThisMonth, setSalesThisMonth] = useState(0);

  useEffect(() => {
    // Obtener los datos de las etiquetas
    const fetchTags = async () => {
      const tagSnapshot = await getDocs(collection(db, 'tags'));
      const tags = tagSnapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = { name: doc.data().name, color: doc.data().color };
        return acc;
      }, {});
      setTagMap(tags);
    };

    fetchTags();
  }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'prospects'), (snapshot) => {
      const data = snapshot.docs.map(doc => doc.data());
      const aggregatedData = data.reduce((acc, curr) => {
        const tagId = curr.tag; // Suponiendo que "tag" es el campo que contiene el ID de la etiqueta
        if (!acc[tagId]) {
          acc[tagId] = 0;
        }
        acc[tagId]++;
        return acc;
      }, {});

      const formattedData = Object.keys(aggregatedData).map(key => ({
        tag: key,
        count: aggregatedData[key],
        name: tagMap[key]?.name || key, // Usa el nombre de la etiqueta o el ID si no se encuentra
        color: tagMap[key]?.color || '#8884d8' // Usa el color de la etiqueta o un color por defecto
      }));

      setProspectData(formattedData);
    });

    return () => unsubscribe();
  }, [tagMap]);

  useEffect(() => {
    // Obtener el total de pedidos
    const fetchOrders = async () => {
      const orderSnapshot = await getDocs(collection(db, 'orders'));
      setTotalOrders(orderSnapshot.size);

      // Obtener la suma de ventas de este mes
      const startOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      const endOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);

      const ordersThisMonthQuery = query(
        collection(db, 'orders'),
        where('date', '>=', startOfMonth),
        where('date', '<=', endOfMonth)
      );

      const ordersThisMonthSnapshot = await getDocs(ordersThisMonthQuery);
      const totalSales = ordersThisMonthSnapshot.docs.reduce((acc, doc) => acc + doc.data().total, 0);

      setSalesThisMonth(totalSales);
    };

    fetchOrders();
  }, []);

  useEffect(() => {
    // Obtener el total de productos
    const fetchProducts = async () => {
      const productSnapshot = await getDocs(collection(db, 'products'));
      setTotalProducts(productSnapshot.size);
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    // Obtener el total de clientes
    const fetchCustomers = async () => {
      const customerSnapshot = await getDocs(collection(db, 'customers'));
      setTotalCustomers(customerSnapshot.size);
    };

    fetchCustomers();
  }, []);

  const totalProspects = prospectData.reduce((acc, curr) => acc + curr.count, 0);

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold mb-6">Sales Dashboard</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
        {/* Tarjeta de Conteo de Prospectos */}
        <div className="bg-white p-4 rounded-lg shadow">
          <h3 className="text-sm font-semibold text-gray-500">Total Prospectos</h3>
          <div className="text-2xl font-bold">{totalProspects}</div>
        </div>

        {/* Tarjeta de Conteo de Pedidos */}
        <div className="bg-white p-4 rounded-lg shadow">
          <h3 className="text-sm font-semibold text-gray-500">Total Pedidos</h3>
          <div className="text-2xl font-bold">{totalOrders}</div>
        </div>

        {/* Tarjeta de Conteo de Productos */}
        <div className="bg-white p-4 rounded-lg shadow">
          <h3 className="text-sm font-semibold text-gray-500">Total Productos</h3>
          <div className="text-2xl font-bold">{totalProducts}</div>
        </div>

        {/* Tarjeta de Conteo de Clientes */}
        <div className="bg-white p-4 rounded-lg shadow">
          <h3 className="text-sm font-semibold text-gray-500">Total Clientes</h3>
          <div className="text-2xl font-bold">{totalCustomers}</div>
        </div>

        {/* Tarjeta de Suma de Ventas del Mes */}
        <div className="bg-white p-4 rounded-lg shadow col-span-1 md:col-span-2 lg:col-span-4">
          <h3 className="text-sm font-semibold text-gray-500">Ventas del Mes</h3>
          <div className="text-2xl font-bold">${salesThisMonth.toFixed(2)}</div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-4">Sales Growth by Market Segment</h3>
          <PieChart width={400} height={400}>
            <Pie
              data={prospectData}
              dataKey="count"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={70}
              outerRadius={150}
              fill="#8884d8"
              label={({ name, percentage }) => `${name}: ${percentage}%`}
            >
              {prospectData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
