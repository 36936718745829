import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';

const OrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const ordersSnapshot = await getDocs(collection(db, 'pedidos'));
        const ordersList = await Promise.all(ordersSnapshot.docs.map(async (doc) => {
          const orderData = doc.data();
          const clientDoc = await getDoc(doc(db, 'clients', orderData.clientId));
          const clientName = clientDoc.exists() ? clientDoc.data().name : 'Cliente desconocido';
          
          return {
            id: doc.id,
            clientName,
            createdAt: orderData.createdAt,
            total: orderData.total,
            status: orderData.status,
          };
        }));

        console.log('Pedidos obtenidos:', ordersList); // Salida de depuración
        setOrders(ordersList);
      } catch (error) {
        console.error('Error al obtener pedidos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  if (loading) {
    return <p>Cargando pedidos...</p>;
  }

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Pedidos</h2>
      {orders.length === 0 ? (
        <p>No hay pedidos disponibles.</p>
      ) : (
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b text-left">ID</th>
              <th className="py-2 px-4 border-b text-left">Cliente</th>
              <th className="py-2 px-4 border-b text-left">Fecha de Creación</th>
              <th className="py-2 px-4 border-b text-left">Estado</th>
              <th className="py-2 px-4 border-b text-right">Total</th>
            </tr>
          </thead>
          <tbody>
            {orders.map(order => (
              <tr key={order.id}>
                <td className="py-2 px-4 border-b">{order.id}</td>
                <td className="py-2 px-4 border-b">{order.clientName}</td>
                <td className="py-2 px-4 border-b">
                  {order.createdAt && order.createdAt.toDate ? order.createdAt.toDate().toLocaleDateString() : 'Fecha no disponible'}
                </td>
                <td className="py-2 px-4 border-b">{order.status}</td>
                <td className="py-2 px-4 border-b text-right">
                  {order.total !== undefined ? `$${order.total.toFixed(2)}` : 'Total no disponible'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default OrdersPage;
